// Define the interface for the option model
export interface Option {
  viewValue: string;
  value: string;
}

// Define the array of options
export const OPTIONS: Option[] = [
  { viewValue: 'Governorates', value: 'governorates' },
  { viewValue: 'Neighbourhoods', value: 'neighbourhoods' },
  { viewValue: 'Blocks', value: 'blocks' },
  { viewValue: 'Master Plans', value: 'master_plans' },
  { viewValue: 'Projects/Compounds', value: 'projects' },
  { viewValue: 'Parcels', value: 'parcels' },
  { viewValue: 'Streets', value: 'streets' },
  { viewValue: 'Project Streets', value: 'project_streets' },
  { viewValue: 'Unit Layout', value: 'units' },
  { viewValue: 'Road Layout', value: 'road' },
  { viewValue: 'Parcel setback', value: 'parcel_setback' },
  { viewValue: 'Metro Line', value: 'metro_line' },
  { viewValue: 'Metro station', value: 'metro_station' },
  { viewValue: 'Project Road', value: 'project_road' },
  { viewValue: 'Pcc Parcels', value: 'pcc_parcels' },
  { viewValue: 'Project Details', value: 'project_details' }
];
