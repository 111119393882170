import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";

@Component({
  selector: "app-copy-attribute-progress",
  templateUrl: "./copy-attribute-progress.component.html",
  styleUrls: ["./copy-attribute-progress.component.scss"],
})
export class CopyAttributeProgressComponent {
  selectedColumns: string[] = ["city_id", "city_name","message","status"];
  selectedData: any[] = [];
  success_Count:number;
  failure_count:number;
  // statusMap = {
  //   "1": { label:  },
  //   "0": { label: "Failed", color: "#FF4A4A" },
  // };
     // Method to get CSS class and color based on status code
     getStatusClass(status: any): { class: string; color: string } {
      // Mapping of status codes to classes and colors
      const StatusClassMap = {
        "0": { class: "Failed", color: "#FF4A4A" },
        "1": { class: "Success", color: "#0FEB03" },
        // "2": { class: "Success", color: "green" },
        // "-2": { class: "Accept-invalid", color: "orange" },
        // "0": { class: "Uploaded", color: "purple" },
        // "-3": { class: "Rejected", color: "darkred" },
        // "4": { class: "Accept-progress", color: "yellow" },
        // "3": { class: "Validating", color: "gray" },
      };
      return (
        StatusClassMap[status] || { class: "status-default", color: "black" }
      );
    }
  download_data: any;
  constructor(
    private api: ApiserviceService,
    public dialogRef: MatDialogRef<CopyAttributeProgressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.getProcessData();
    console.log(this.data, "data data");

  }
  getProcessData() {
        this.selectedData = this.data.element.data.cities;
        this.download_data = this.data.element.data.data
        this.failure_count = this.data.element.data.failureCount;
        this.success_Count = this.data.element.data.successCount;
  }
    // Function to download the data as a text file
    downloadDataAsText() {
      // Convert the API response to a string
      const dataToDownload = JSON.stringify(this.download_data, null, 2); // Formatting the JSON with spacing

      // Create a Blob from the string data
      const blob = new Blob([dataToDownload], { type: 'text/plain' });

      // Create a link element to download the Blob as a file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
       // Generate a timestamp using Date.now()
      const timestamp = Date.now();
       // Create a file name with the timestamp
       const fileName = `cross-db-copy-attribute-${timestamp}.txt`;
      link.download = fileName;  // File name for the download

      // Append the link to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link after downloading
      document.body.removeChild(link);
    }
    close() {
      this.dialogRef.close();
    }
}
