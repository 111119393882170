<form [formGroup]="layerDataGroup">
  <mat-slide-toggle
    class="status"
    formControlName="serverStatus"
    [disabled]="isLoading"
    (change)="toggleServerStatus()"
  >
    <span *ngIf="isLoading" class="loading-message">
      Checking server status</span>
      <span *ngIf="!isLoading" class="loading-message">{{ serverStatusText }}</span>
  </mat-slide-toggle>
</form>
