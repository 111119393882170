import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from '../../environments/environment';
import { ApiserviceService } from '../apiservice.service';

@Injectable({
  providedIn: "root",
})
export class SocketService {
  socket = io(environment.gmusUrl, {
    extraHeaders: {
      "user-city": this.apiservice.city_id,
    },
  });

sparkSocket = io(environment.gesUrl, {
  extraHeaders: {
    "user-city": this.apiservice.city_id,
  },
})

  public message$: BehaviorSubject<string> = new BehaviorSubject("");
  public gis_spark_message$: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private apiservice: ApiserviceService) {
    this.socket.on("gis_message", (message) => {
      this.message$.next(message);
    });

    this.sparkSocket.on("gis_spark_message", (message) => {
      this.gis_spark_message$.next(message);
    });

  }
}
