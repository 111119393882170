import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @Input() min: number = 0; // Minimum range value
  @Input() max: number = 100; // Maximum range value
  @Input() percent: number = 0; // Progress percentage
  @Input() isProgress: string = ''; // Additional class binding (optional)

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.percent) {
      console.log('Percent updated:', this.percent);
      this.cdr.detectChanges(); // Trigger change detection for UI update
    }
  }
}
