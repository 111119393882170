import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { NotificationService } from "../services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { GisDataService } from "../gis-data-upload/gis-data-upload.service";
import { ViewReportComponent } from "./view-report/view-report.component";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

export interface options {
  label: string;
  value: string;
}
export interface status {
  status_code: number;
  value: string;
}

@Component({
  selector: "app-calculate-sync",
  templateUrl: "./calculate-sync.component.html",
  styleUrls: ["./calculate-sync.component.css"],
})
export class CalculateSyncComponent implements OnInit {
  status: status[] = [
    { status_code: -1, value: "Invalid" },
    { status_code: 1, value: "Valid" },
    { status_code: 0, value: "Start" },
    { status_code: 2, value: "Accepted" },
    { status_code: -2, value: "Rejected" },
  ];
  public calculate_access = {
    GET: false,
    POST: true,
    PATCH: false,
    DELETE: false,
  };
  public calculate_highway_access = {
    GET: false,
    POST: true,
    PATCH: false,
    DELETE: false,
  };
  public calculate_amenity_distance = {
    GET: false,
    POST: true,
    PATCH: false,
    DELETE: false,
  };
  displayedColumns = [];
  loader1: boolean;
  process_name: any;
  totalCount: any;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  selectedBlock: any;
  parcel = "";
  cluster = "";
  degree = "";
  blocks: any[];
  searchAttr: string = "";
  searchNeigh: string = "";
  searchBlock: string = "";
  gisAttributes: any = [];
  layer: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filter: boolean = false;
  statusAll: string;
  attributeName = "";
  filterNeigh: any;
  reset = "";
  previousProcess: any;
  reportStatus: any = [];
  filteredAttributes: any = [];
  filterAttribute: string;
  filteredNeighs: any = [];

  search_year: string;
  yearList: any = [];
  selectedYear: string = new Date().getFullYear().toString();
  selectedlayer: any;
  sub_categories:any=[];
  returnVal(element, cols) {
    switch (cols) {
      case "Added Date":
        return this.getdateForm(element.added_date);
      case "Existing Count":
        return element.existing_count;
      case "id":
        return element.id;
      case "New Count":
        return element.new_count;
      case "Target Count":
        return element.target_count;
      case "uid":
        return element.uid;
      case "Updated Date":
        return this.getdateForm(element.updated_date);
      case "Status":
        return element.status;
    }
  }
  options: options[] = [
    { label: "Parcel Clusters", value: "cluster" },
    { label: "Direction", value: "direction" },
    { label: "Setback", value: "setback" },
    { label: "Highway/Street facing", value: "highway_facing" },
    { label: "Sync Attribute to Layer Data", value: "sync attribute" },
    { label: "Amenity Distance", value: "amenity distance" },
    { label: "Sikka Visibility", value: "sikka_parcel" },
    { label: "Parcel Visibility", value: "parcel_visibility" },
    { label: "Street Facing Rays", value: "highway rays" },
  ];

  calculateSyncForm: FormGroup;
  loader: boolean = false;
  neighbourhoods: any = [];
  dataSource: any = [];
  refresh: any = [
    {
      name: "Calculate only new records",
      value: "false",
    },
    {
      name: "Delete Existing records and calculate ",
      value: "true",
    },
  ];
  selectedneightUid: any;
  distance: number;
  isRefresh: boolean = true;
  selected: string;
  formValue: any;
  attrName: string;
  subCat: any=[];
  buildingUid: string;
  radius: number;
  limit: number = 10;
  offset: number = 0;
  zoning: string = "";
  ZoningList = [];
  propertySubcatList = [];

  select_zoning = [];
  select_subcat = [];

  search_value: string = "";
  search_subcat: string = "";

  constructor(
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private notify: NotificationService,
    private active: GisDataService
  ) {
    this.menus.setActive("calculate-sync");
    this.active.setActive("");
    this.getAllowResourcesApi();
    this.selected = "cluster";
    this.calculateSyncForm = this.fb.group({
      neigh: [""],
      block: [""],
      distance: [""],
      refresh: [""],
      attrName: [""],
      parcel: [""],
      clusterGids: [""],
      subCat: [""],
      radius: [""],
      buildingUid: [""],
      degree: [""],
      zoning: [""],
      propertysubcat: [""],
    });
    this.selectedForm(this.selected);
    this.getdropdowndata();
    this.getAmenities();
  }

  /**
   * select all zoning
   * @param event
   */
  selectAll(event) {
    if (event.checked) {
      this.select_zoning = [];
      this.ZoningList.map((e) => {
        this.select_zoning.push(e);
      });
      this.calculateSyncForm.patchValue({
        zoning: this.select_zoning,
      });
    } else {
      this.select_zoning = [];
      this.calculateSyncForm.patchValue({
        zoning: this.select_zoning,
      });
    }
  }

  /**
   * check select all zoning
   * @returns boolean value
   */
  isCheckedAll() {
    return this.calculateSyncForm.value.zoning.length == this.ZoningList.length;
  }

  /**
   * select one zoning value from dropdown
   * @param data
   */
  selectOne(data) {
    let lastSelectedValue = data.value;
    const isChecked = data.selected;
    if (isChecked) {
      if (this.select_zoning.indexOf(lastSelectedValue) == -1) {
        this.select_zoning.push(lastSelectedValue);
      }
    } else {
      const index = this.select_zoning.indexOf(lastSelectedValue);

      if (index > -1) {
        this.select_zoning.splice(index, 1);
      }
    }
    this.calculateSyncForm.patchValue({
      zoning: this.select_zoning,
    });
  }

  /**
   * select all property subcat value
   * @param event
   */
  selectSubcatAll(event) {
    if (event.checked) {
      this.select_subcat = [];
      this.propertySubcatList.map((e) => {
        this.select_subcat.push(e);
      });
      this.calculateSyncForm.patchValue({
        propertysubcat: this.select_subcat,
      });
    } else {
      this.select_subcat = [];
      this.calculateSyncForm.patchValue({
        propertysubcat: this.select_subcat,
      });
    }
  }

  /**
   * check select all subcat
   * @returns boolean value
   */
  isCheckedSubcatAll() {
    return (
      this.calculateSyncForm.value.propertysubcat.length ==
      this.propertySubcatList?.length
    );
  }

  /**
   * select one property subcat value from dropdown
   * @param data
   */
  selectSubOne(data) {
    let lastSelectedValue = data.value;
    const isChecked = data.selected;
    if (isChecked) {
      if (this.select_subcat.indexOf(lastSelectedValue) == -1) {
        this.select_subcat.push(lastSelectedValue);
      }
    } else {
      const index = this.select_subcat.indexOf(lastSelectedValue);

      if (index > -1) {
        this.select_subcat.splice(index, 1);
      }
    }
    this.calculateSyncForm.patchValue({
      propertysubcat: this.select_subcat,
    });
  }

  /**
   * Get settings for amenities
   */
  getAmenities(){
      this.api.getGmsData('settings?search_text=amenity_distance').subscribe(
        {
          next:(res:any)=>{
            if(res.data?.length){
              let setting = res.data[0];
              this.sub_categories = setting.value.split(',');
            }
          },
          error: ()=>{

          }
        }
      )
  }
  /**
   * this is a function to get dropdown data
   */
  getdropdowndata() {
    let url = "get-dropdown-data?year=" + this.selectedYear;
    this.ZoningList=[];
    this.propertySubcatList=[];
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.propertySubcatList = res.propertySubCat;
          this.ZoningList = res.zoning;
        }
      },
      error: (err) => {
        console.log(err.error.message);
      },
    });
  }
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.calculate_access = this.fnc.checkResourceAccess(
        "calculateParcelHighway",
        false
      );
      this.calculate_highway_access = this.fnc.checkResourceAccess(
        "calculate_highway_facing_rays",
        false
      );
      this.calculate_amenity_distance = this.fnc.checkResourceAccess(
        "calculate_amenity_distance",
        false
      );
      this.getAttributes();
      this.getNeighbourhoods();
    });
  }

  /**
   * function for Status filter on sync/calculate table
   * @param param
   * @param type
   */
  filterByStatus(param, type) {
    if (type == "status") {
      if (param == "All") {
        this.statusAll = "-2,-1,0,1,2";
        this.getReports(this.process_name, "filter");
      } else {
        this.statusAll = param.toString();
        this.getReports(this.process_name, "filter");
      }
    }
    if (type == "neigh") {
      if (param == "All") {
        this.filterNeigh = "";
        this.getReports(this.process_name, "filter");
      } else {
        this.filterNeigh = param.toString();
        this.getReports(this.process_name, "filter");
      }
    }
    if (type == "attribute") {
      if (param == "All") {
        this.filterAttribute = "";
        this.getReports(this.process_name, "filter");
      } else {
        this.filterAttribute = param.toString();
        this.getReports(this.process_name, "filter");
      }
    }
  }

  getStatus(val) {
    return this.fnc.getArrayValue("status_code", val, this.status).value;
  }
  getLabel(val: string) {
    if (val) {
      let v = val.split("**");
      return v.toString();
    }
    return "";
  }

  getNeighbourhoods() {
    this.loader = true;
    this.neighbourhoods=[];
    let url = `neighbourhoods/json`;
    if (this.selectedYear) {
      url += `?year=${this.selectedYear}`;
    }
    this.api.getGpsData(url).subscribe(
     {next: (data: any) => {
        this.loader = false;
        if (data.length) {
          this.neighbourhoods = data;
        }
      },
      error:() => {
        this.loader = false;
      }}
    );
  }


  getBlocks(neighUid) {
    this.loader = true;
     let url = `blocks/json?neigh_uid=${neighUid}&return_geometry=true`;

     if (this.selectedYear) {
       url += `&year=${this.selectedYear}`;
     }
     this.blocks = [];
    this.api
      .getGpsData(url)
      .subscribe(
        {next: (res: any) => {
          this.loader = false;
          this.blocks = res;
        },
        error:() => {
          this.selectedBlock = null;
          this.loader = false;
          this.notify.notify("No Blocks found in this Neighbourhood", "warn");
        }}
      );
  }

  getAttributes() {
    let url = `attributes?status=1&sort_by=attribute_name&sort_asc=true&layer=building`;
    this.api.getGmsData(url).subscribe((res: any) => {
      if (res.data?.length) {
        this.gisAttributes = res.data;
        // res.forEach(element => {
        //   this.gisAttributes.push(element)
        // });
      }
    });
  }

  // SELECTED SECTION
  //to get value of what checkbox is selected
  selectedForm(event) {
    this.searchNeigh = undefined;
    this.selectedBlock = undefined;
    this.selectedneightUid = null;
    this.distance = null;
    this.isRefresh = true;
    this.formValue = event;
    if (this.formValue) {
      this.calculateSyncForm.patchValue({
        neigh: "",
        refresh: "",
        attrName: "",
        block: "",
        parcel: "",
        clusterGids: "",
        radius: "",
        zoning: "",
        degree: "",
        distance: "",
        propertysubcat: "",
      });
      setTimeout(() => {
        this.calculateSyncForm.controls.refresh.patchValue("true");
        if (this.formValue == "sikka_parcel") {
          this.calculateSyncForm.controls.radius.patchValue(11);
          this.calculateSyncForm.controls.zoning.patchValue("PRI,INV");
        } else if (this.formValue == "highway rays") {
          this.calculateSyncForm.controls.radius.patchValue(100);
          this.calculateSyncForm.controls.degree.patchValue(180);
        } else if (this.formValue == "highway_facing") {
          this.calculateSyncForm.controls.distance.patchValue(150);
          this.getdropdowndata();
        }
      }, 100);
    }
  }

  selectedNeigh(neigh) {
    this.selectedneightUid = neigh;
    this.degree = "180";
    if (this.formValue == "highway_facing") {
    } else {
      if (this.selectedneightUid) {
        this.getBlocks(this.selectedneightUid);
      }
    }
  }

  selectedRefresh(value) {
    //concole.log(value);
    this.isRefresh = value;
  }

  selectBlock(value) {
    this.selectedBlock = value;
  }
  //SELECTED SECTION END

  //
  openConfirmation() {
    if (this.formValue == "highway_facing") {
      if (!this.calculate_access.POST) {
        this.notify.notify(
          "You are not authorized to Calculate ",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      if (this.distance == null || this.distance == undefined) {
        this.notify.notify("Please Enter Distance", "warn");
        return;
      }
      if (this.isRefresh == null || this.isRefresh == undefined) {
        this.notify.notify("Please Select Calculation Refresh Value", "warn");
        return;
      }

      let propertysubcat = this.calculateSyncForm.value.propertysubcat;
      let zone = this.calculateSyncForm.value.zoning;

      if (zone == "" || zone == null || zone == undefined) {
        this.notify.notify("Please Select zone", "warn");
        return;
      }

      if (
        propertysubcat == "" ||
        propertysubcat == null ||
        propertysubcat == undefined
      ) {
        this.notify.notify("Please Select property subcat", "warn");
        return;
      }

      let _data = {
        parent_data: this,
        message: "Do you really want to run Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "cluster") {
      if (!this.calculate_access.POST) {
        this.notify.notify(
          "You are not authorized to Calculate ",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let _data = {
        parent_data: this,
        message: "Do you really want to run Cluster Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "amenity distance") {
      if (!this.calculate_amenity_distance.POST) {
        this.notify.notify(
          "You are not authorized to Calculate ",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      if (this.subCat.length == 0) {
        this.notify.notify("Please Input Amenity Sub Category", "warn");
        return;
      }
      if (this.radius == null || this.radius == undefined) {
        this.notify.notify("Please Enter Radius value", "warn");
        return;
      }
      if (this.isRefresh == null || this.isRefresh == undefined) {
        this.notify.notify("Please Select Calculation Refresh Value", "warn");
        return;
      }
      let _data = {
        parent_data: this,
        message: "Do you really want to run Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "direction") {
      if (!this.calculate_access.POST) {
        this.notify.notify(
          "You are not authorized to Calculate Direction",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      if (this.isRefresh == null || this.isRefresh == undefined) {
        this.notify.notify("Please Select Calculation Refresh Value", "warn");
        return;
      }
      let _data = {
        parent_data: this,
        message: "Do you really want to run Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "setback") {
      if (!this.calculate_access.POST) {
        this.notify.notify(
          "You are not authorized to Calculate Setback",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let _data = {
        parent_data: this,
        message: "Do you really want to run Setback Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "highway rays") {
      if (!this.calculate_highway_access.POST) {
        this.notify.notify(
          "You are not authorized to Calculate Street Facing Rays",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      if (this.radius == null || this.radius == undefined) {
        this.notify.notify("Please enter Radius", "warn");
        return;
      }
      if (this.isRefresh == null || this.isRefresh == undefined) {
        this.notify.notify("Please select Refresh Value", "warn");
        return;
      }
      if (this.degree == null || this.degree == undefined) {
        this.notify.notify("Please enter Degree", "warn");
        return;
      }
      let _data = {
        parent_data: this,
        message: "Do you really want to run Highway Access Calculation?",
      };
      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "parcel_visibility") {
      if (!this.calculate_access.POST) {
        this.notify.notify(
          "You are not authorized to Parcel Visibility",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      // if (this.isRefresh == null || this.isRefresh == undefined) {
      //   this.notify.notify("Please Select Calculation Refresh Value", "Dismiss");
      //   return;
      // }
      let _data = {
        parent_data: this,
        message: "Do you really want to run  Parcel Visibility Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
    if (this.formValue == "sikka_parcel") {
      if (!this.calculate_access.POST) {
        this.notify.notify(
          "You are not authorized to Sikka Visibility",
          "warn",
          5000
        );
        return;
      }
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      // if (this.isRefresh == null || this.isRefresh == undefined) {
      //   this.notify.notify("Please Select Calculation Refresh Value", "Dismiss");
      //   return;
      // }
      let _data = {
        parent_data: this,
        message: "Do you really want to run  Sikka Visibility Calculation?",
      };

      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: "350px",
        data: _data,
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  openView(type: any, data: any = null) {
    if (type) {
      var _data = {
        type: type,
        data: data,
        pdata: this,
      };
    }
    const dialogRef = this.dialog.open(ViewReportComponent, {
      width: "800px",
      data: _data,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  sync(val) {
    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to Sync ", "warn", 5000);
      return;
    }
    if (
      this.attributeName == null ||
      this.attributeName == undefined ||
      this.attributeName == ""
    ) {
      this.notify.notify("Enter Attribute to Sync ", "warn", 5000);
      return;
    }

    let attrName = val.value.attrName;
    if (attrName) {
      this.loader = true;
      let url = `attributes-sync?attribute_name=${attrName}&year=${this.selectedYear}`;
      //concole.log(url);
      this.api.getGmsData(url).subscribe(
        {next:(res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
        error:(err) => {
          this.loader = false;
          this.notify.notify(err.message, "error");
        }}
      );
    }
  }

  confirmDialogYes(confirm, action) {
    if (confirm == "YES") {
      this.calculate();
    }
  }

  //create year dropdown
  getYears() {
    const currentYear = new Date().getFullYear()
    const startYear  = 2009;
    for (var i = currentYear; i >= startYear; i--) {
      this.yearList.push({ val: (i).toString() });
    }
  }
  setGlobleYear() {
    this.blocks = [];
    this.selectedBlock = null;
    this.selectedneightUid = null;
    this.calculateSyncForm.patchValue({ neigh: "" });
    this.getNeighbourhoods();
    if (this.selectedlayer) {
      this.getReports(this.selectedlayer, "button");
    }
    if (this.formValue == "highway_facing") {
      this.getdropdowndata();
    }
  }

  checkErrorLen(val: string) {
    if (val && val.length > 260) {
      let v1 = val.substring(0, 260);
      let v2 = val.substring(260, val.length);
      return v1 + " " + v2;
    } else {
      return val;
    }
  }

  calculate() {
    /////////////////////// 1 ///////////////////////////
    if (this.formValue == "highway_facing") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      if (this.distance == null || this.distance == undefined) {
        this.notify.notify("Please enter Distance", "warn");
        return;
      }
      if (this.refresh == null || this.refresh == undefined) {
        this.notify.notify("Please select refresh value", "warn");
        return;
      }

      let neigh_uid = this.calculateSyncForm.value.neigh;
      let propertysubcat = this.calculateSyncForm.value.propertysubcat;
      let zone = this.calculateSyncForm.value.zoning;

      let sub_cat = "";
      let zoning = "";

      if (propertysubcat) {
        propertysubcat.map((e, k) => {
          sub_cat += e;
          if (k < propertysubcat.length - 1) {
            sub_cat += "**";
          }
        });
      } else {
        this.notify.notify("Please select property sub cat", "warn");
        return;
      }
      if (zone) {
        zone.map((e, k) => {
          zoning += e;
          if (k < zone.length - 1) {
            zoning += "**";
          }
        });
      } else {
        this.notify.notify("Please select zoning", "warn");
        return;
      }

      let url = `calculate-highway-facing?neigh_uid=${neigh_uid}&distance=${this.distance}&force_delete=${this.isRefresh}`;
      if (sub_cat) url += `&subcat=${sub_cat}`;
      if (zoning) url += `&zoning=${zoning}`;

      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      url += `&block_uid=null`;

      this.loader = true;
      this.api.getGesData(url).subscribe(
       {next: (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
       error:(err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
    }

    /////////////////////////// 2 //////////////////////////////////
    if (this.formValue == "cluster") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let url;
      if (
        this.selectedneightUid != null ||
        this.selectedneightUid != undefined
      ) {
        url = `calculate-parcel-cluster?uid=${this.selectedneightUid}&selected_type=neighbourhood&added_by=${this.api.user_id}&action=add`;
      }
      if (this.selectedBlock != null || this.selectedBlock != undefined) {
        url = `calculate-parcel-cluster?uid=${this.selectedBlock}&selected_type=block&added_by=${this.api.user_id}&action=add`;
      }
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe({
        next:(res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
        error:(err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
      setTimeout(() => {
        this.notify.notify(
          "Request has been Submitted to server",
          "success",
          5000
        );
        if (this.selectedlayer) this.getReports(this.selectedlayer, "filter");
      }, 3000);

    }
    //////////////////////////// 3 ///////////////////////////////////
    if (this.formValue == "amenity distance") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      if (this.subCat.length==0) {
        this.notify.notify("Please Enter Sub Category", "warn");
        return;
      }
      if (this.radius == null || this.radius == undefined) {
        this.notify.notify("Please Enter Radius", "warn");
        return;
      }
      if (this.isRefresh == null || this.isRefresh == undefined) {
        this.notify.notify("Please select Refresh Value", "warn");
        return;
      }
      let subcats = this.subCat ? this.subCat.toString():'';
      let url = `calculate-amenity-distance?neigh_uid=${this.selectedneightUid}&amenity_sub_cat=${subcats}&radius=${this.radius}&is_refresh=${this.isRefresh}&added_by=${this.api.user_id}`;
      //concole.log(url);
      if (this.selectedBlock) {
        url += `&block_uid=${this.selectedBlock}`;
      }
      if (
        this.buildingUid != null &&
        this.buildingUid != "" &&
        this.buildingUid != "" &&
        this.buildingUid != undefined
      ) {
        url += `&building_uid=${this.buildingUid}`;
      }
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe(
        {next:(res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
        error:(err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
    }
    ////////////////////////////// 4 ////////////////////////////////
    if (this.formValue == "direction") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let url = `calculate-direction-info?neigh_uid=${this.selectedneightUid}&is_refresh=${this.isRefresh}&user_id=${this.api.user_id}`;
      if (this.selectedBlock) {
        url += `&block_uid=${this.selectedBlock}`;
      }
      if (this.parcel) {
        url += `&parcel_ids=${this.parcel}`;
      }
      if (this.cluster) {
        url += `&cluster_gids=${this.cluster}`;
      }
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe(
       {next: (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
        error: (err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
    }
    ////////////////////////////// 5 ////////////////////////////////
    if (this.formValue == "setback") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let url = `calculate-setback-sync?neigh_uid=${this.selectedneightUid}&user_id=${this.api.user_id}`;
      if (this.selectedBlock) {
        url += `&block_uid=${this.selectedBlock}`;
      }
      if (this.parcel) {
        url += `&parcel_ids=${this.parcel}`;
      }
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe(
      {next:  (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
       error: (err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
    }
    /////////////////////////////// 6 //////////////////////////////
    if (this.formValue == "highway rays") {
      let url = `highway_facing_rays_sync?neigh_uid=${this.selectedneightUid}&radius=${this.radius}&is_refresh=${this.isRefresh}&user_id=${this.api.user_id}`;
      if (this.selectedBlock) {
        url += `&block_uid=${this.selectedBlock}`;
      }
      if (this.parcel) {
        url += `&parcel_ids=${this.parcel}`;
      }
      if (this.degree) {
        url += `&fov=${this.degree}`;
      }
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe(
       {next: (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
       error: (err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
    }
    ///////////////////////////////// 7 /////////////////////////////////
    if (this.formValue == "parcel_visibility") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let url = `calculate-parcel-visibility?neigh_uid=${this.selectedneightUid}&user_id=${this.api.user_id}`;
      if (this.selectedBlock) {
        url += `&block_uid=${this.selectedBlock}`;
      }
      if (this.parcel) {
        url += `&parcel_ids=${this.parcel}`;
      }
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe({next:
        (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
        error:(err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
      setTimeout(() => {
        this.loader =false;
        this.notify.notify(
          "Request has been Submitted to server",
          "success",
          5000
        );
        if (this.selectedlayer) this.getReports(this.selectedlayer, "filter");
      }, 3000);
    }
    //////////////////////////// 8 //////////////////////////////////
    if (this.formValue == "sikka_parcel") {
      if (
        this.selectedneightUid == null ||
        this.selectedneightUid == undefined
      ) {
        this.notify.notify("Please select Neighbourhood", "warn");
        return;
      }
      let url = `calculate-sikka-parcel?neigh_uid=${this.selectedneightUid}&user_id=${this.api.user_id}`;
      if (this.selectedBlock) {
        url += `&block_uid=${this.selectedBlock}`;
      }
      if (this.parcel) {
        url += `&parcel_ids=${this.parcel}`;
      }
      if (this.radius) url += `&radius=${this.radius}`;
      if (this.zoning) url += `&zoning=${this.zoning}`;
      if (this.selectedYear) {
        url += `&year=${this.selectedYear}`;
      }
      this.loader = true;
      this.api.getGesData(url).subscribe(
        {next:(res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify(
              "Request has been Submitted to server",
              "success",
              5000
            );
            if (this.selectedlayer)
              this.getReports(this.selectedlayer, "filter");
          } else {
            // this.notify.notify("Internal server error", 'warn', 5000);
          }
        },
        error: (err) => {
          this.loader = false;
          // this.notify.notify(this.checkErrorLen(err.message), "error");
        }}
      );
    }
  }

  getFilters(action) {
    let url = `sync-calculate-process?layer=${this.layer}&process_name=${this.process_name}&limit=${this.limit}&offset=${this.offset}&action=${action}`;
    this.api.getGesData(url).subscribe(
      {next: (res: any) => {
        if (res && res.data?.length) {
          if (action == "neighbourhood") {
            this.filteredNeighs = res.data;
          }
          if (action == "status") {
            this.reportStatus = res.data;
          }
          if (action == "attribute") {
            this.filteredAttributes = res.data;
          }
        }
      },
      error: (err) => {
        console.log(err.error.message);
      }}
    );
  }

  getReports(process, from) {
    this.dataSource = [];
    if (from == "button") {
      this.selectedlayer = process;
      if (
        this.selectedlayer == "sync attribute" ||
        this.selectedlayer == "parcel_visibility" ||
        this.selectedlayer == "cluster" ||
        this.selectedlayer == "direction"
      ) {
        this.displayedColumns = [
          "sno",
          "id",
          "uid",
          "Added Date",
          "Existing Count",
          "New Count",
          "Updated Date",
          "Log File",
          "Error File",
          "Status",
          "Actions",
        ];
      } else if (this.selectedlayer == "highway_facing") {
        this.displayedColumns = [
          "sno",
          "id",
          "uid",
          "Added Date",
          "Existing Count",
          "New Count",
          "Target Count",
          "Updated Date",
          "Zoning",
          "Subcat",
          "Log File",
          "Error File",
          "Status",
          "Actions",
        ];
      } else if (this.selectedlayer == "amenity distance") {
        this.displayedColumns = [
          "sno",
          "id",
          "uid",
          "Added Date",
          "Existing Count",
          "New Count",
          "Updated Date",
          "Subcat",
          "Log File",
          "Error File",
          "Status",
          "Actions",
        ];
      } else if (this.selectedlayer == "sikka_parcel") {
        this.displayedColumns = [
          "sno",
          "id",
          "uid",
          "Added Date",
          "Existing Count",
          "New Count",
          "Updated Date",
          "Zoning",
          "Log File",
          "Error File",
          "Status",
          "Actions",
        ];
      } else {
        this.displayedColumns = [
          "sno",
          "id",
          "uid",
          "Added Date",
          "Existing Count",
          "New Count",
          "Updated Date",
          "Log File",
          "Error File",
          "Status",
          "Actions",
        ];
      }
      this.filterAttribute = "";
      this.filterNeigh = "";
      this.statusAll = "";
      this.searchNeigh = "";
      this.reset = "All";
      setTimeout(() => {
        this.reset = "";
      }, 100);
    }
    let url;
    if (process.includes("facing")) {
      this.layer = "neighbourhood";
      this.process_name = "highway_facing";
    } else if (process.includes("cluster")) {
      this.layer = "neighbourhood";
      this.process_name = "cluster";
    } else if (process.includes("amenity")) {
      if (!this.calculate_amenity_distance.GET) {
        this.notify.notify(
          "You are not Authorized to View Amenities Distance Report",
          "warn",
          5000
        );
        return;
      }
      this.layer = "neighbourhood";
      this.process_name = "amenity distance";
    } else if (process.includes("direction")) {
      this.layer = "neighbourhood";
      this.process_name = "direction";
    } else if (process.includes("attribute")) {
      this.layer = "attribute";
      this.process_name = "sync attribute";
    } else if (process.includes("setback")) {
      this.layer = "neighbourhood";
      this.process_name = "setback";
    } else if (process.includes("access") || process.includes("rays")) {
      if (!this.calculate_highway_access.GET) {
        this.notify.notify(
          "You are not authorized to View Highway Access Reports",
          "warn",
          5000
        );
        return;
      }
      this.layer = "neighbourhood";
      this.process_name = "highway rays";
    } else if (process.includes("parcel_visibility")) {
      this.layer = "neighbourhood";
      this.process_name = "parcel_visibility";
    } else if (process.includes("sikka_parcel")) {
      this.layer = "neighbourhood";
      this.process_name = "sikka_parcel";
    } else {
      return;
    }
    if (from == "button" || from == "filter") {
      this.filteredNeighs = [];
      this.reportStatus = [];
      this.filteredAttributes = [];
      this.paginator.pageIndex = 0;
      this.offset = 0;
      this.getFilters("neighbourhood");
      this.getFilters("status");
      this.getFilters("attribute");
      // this.reportsTotalCount();
    }
    if (this.process_name != process) {
      this.statusAll = "";
      this.filterNeigh = "";
      this.filterAttribute = "";
    }
    let status = this.statusAll;
    let neigh = this.filterNeigh;
    let filterByAttribute = this.filterAttribute;
    this.active.setActive(process);
    url = `sync-calculate-process?layer=${this.layer}&process_name=${this.process_name}&limit=${this.limit}&offset=${this.offset}&action=get`;
    if (status) {
      url += "&status=" + status;
    }
    if (neigh) {
      url += "&neigh_uids=" + neigh;
    }
    if (filterByAttribute) {
      url += "&neigh_uids=" + filterByAttribute;
    }
    if (this.offset == 0) {
      url += "&is_count=true";
    }
    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    this.loader1 = true;
    this.api.getGesData(url).subscribe(
      {next:(res: any) => {
        this.searchNeigh = "";
        this.loader1 = false;
        this.filter = true;
        if (this.offset == 0) {
          this.totalCount = res.totalCount;
        }
        if (res && res?.data?.length) {
          this.dataSource = new MatTableDataSource(res?.data);
          this.dataSource.sort = this.sort;
          // this.reportsTotalCount();
        } else {
          this.notify.notify("No Results Found!", "warn", 5000);
        }
      },
      error:  (err) => {
        this.loader1 = false;
        this.notify.notify(err.error.message, "error", 3000);
      }}
    );
  }

  download_process_log(layer: any,process_name: any,id: any){
    let url = `sync-calculate-process?id=${id}&layer=${layer}&process_name=${process_name}&action=get_log`;
    this.api.getGesData(url).subscribe(
     {next: (res: any) => {
        if (res && res?.data?.length) {
          let keys: any[],text="";
          keys= Object.keys(res.data[0].process_log)
          keys.map(d=>{
            text+=res.data[0].process_log[d]+"\n"
          })
         var a = document.createElement('a');
         var file = new Blob([text], {type: 'text/plain'});
         a.href = URL.createObjectURL(file);
         a.download = 'Process_log_'+id+'.txt';
         a.click();

        } else {
          this.notify.notify("No Results Found!", "warn", 5000);
        }
      },
      error:  (err) => {
        this.loader1 = false;
        this.notify.notify(err.error.message, "error", 3000);
      }}
    );
  }

  // reportsTotalCount() {
  //   let neigh = this.filterNeigh, status = this.statusAll;
  //   let url = `sync-calculate-process?layer=${this.layer}&process_name=${this.process_name}&action=get`;
  //   if (neigh) {
  //     url += "&neigh_uids=" + neigh;
  //   }
  //   if (status) {
  //     url += "&status=" + status;
  //   }
  //   this.api.getGisData(url).subscribe((res: any) => {
  //     this.totalCount = res.length;
  //   })
  // }

  stopProcess(id) {
    if (this.process_name.includes("facing")) {
      this.process_name = "gis_calculate_highway_facing";
    } else if (this.process_name.includes("cluster")) {
      this.process_name = "gis_parcel_cluster";
    } else if (this.process_name.includes("amenity")) {
      this.process_name = "gis_get_distance_amenity_parcel";
    } else if (this.process_name.includes("attribute")) {
      this.process_name = "gis_properties_attribute_sync";
    } else if (this.process_name.includes("direction")) {
      this.process_name = "gis_calculate_directions";
    } else if (this.process_name.includes("setback")) {
      this.process_name = "gis_calculate_setback";
    } else if (this.process_name.includes("access")) {
      this.process_name = "gis_highway_facing_rays_for_sync";
    } else if (this.process_name.includes("rays")) {
      this.process_name = "gis_calculate_highway_access";
    } else if (this.process_name.includes("visibility")) {
      this.process_name = "parcel_visibility";
    } else if (this.process_name.includes("sikka")) {
      this.process_name = "sikka_parcel";
    } else {
      return;
    }
    let upload_id = id;
    this.loader1 = true;
    this.api
      .getGmsData(
        `stop-process?process_name=${this.process_name}&upload_id=${upload_id}&user_id=${this.api.user_id}`
      )
      .subscribe(
        {next: (res: any) => {
          this.notify.notify("Request Submitted", "success", 4000);
          this.loader1 = false;
          this.getReports(this.selectedlayer, "button");
        },
        error: (err) => {
          this.loader1 = false;
          this.notify.notify(err.error.message, "error", 4000);
        }}
      );
  }

  getActive_(e) {
    return this.active.getIsActive(e.value);
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date, true);
    } else {
      return "";
    }
  }
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = e.pageSize * e.pageIndex;
    }
    this.getReports(this.process_name, "page");
  }
  ngOnInit() {
    setTimeout(() => {
      this.getYears();
    }, 500);
  }
}
