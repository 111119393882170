<form [formGroup]="manageUploadedDataGroup">
  <div class="manage-template-data pt-4 p-3">
    <div class="d-flex align-items-center justify-content-between gap-20">
      <div class="d-flex align-items-center gap-20">
        <!-- Back Button with SVG Icon -->
        <div class="d-flex align-items-center">
          <button
            type="button"
            (click)="goBack()"
            class="border-none bg-none cursor-pointer"
          >
            <svg
              class="mx-1"
              xmlns="http://www.w3.org/2000/svg"
              width="13.991"
              height="24.817"
              viewBox="0 0 12.991 23.817"
            >
              <path
                id="XMLID_225_"
                d="M23.5,75.317a1.083,1.083,0,0,0-1.531,0l-10.06,10.06L1.849,75.317A1.083,1.083,0,0,0,.318,76.848L11.144,87.674a1.082,1.082,0,0,0,1.531,0L23.5,76.848A1.082,1.082,0,0,0,23.5,75.317Z"
                transform="translate(87.991 0) rotate(90)"
                fill="#ff9d5a"
                stroke="#ff9d5a"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>

        <!-- Title -->
        <h6 class="title m-0 font-weight-bold">Manage Uploaded CSV</h6>

        <!-- Table Selection -->
        <div class="table-selection-container pl-4">
          <app-gis-select
            [label]="'Select Table Name'"
            [options]="options"
            (selectionChangecustom)="changeTableSelection($event)"
            formControlName="select_table_name"
          >
          </app-gis-select>
        </div>
      </div>
      <div class="d-flex align-items-center gap-20">
        <ng-container *ngIf="selectedRows?.length > 0">
          <!-- delete Template Button -->
          <app-gis-primary-button
            [button_label]="'Delete Selected'"
            [isDisabled]="false"
            (onAction)="deleteSelected();"
            [class]="'delete-btn'"
            [isBadgeAdded]="false"
          >
          </app-gis-primary-button>
        </ng-container>

        <ng-container>
          <!-- Filter Template Button -->
          <app-gis-primary-button
            [button_label]="'Filter'"
            [isDisabled]="false"
            (onAction)="openFiltersPanel();"
            [class]="'cancel-btn'"
            [isBadgeAdded]="true"
            [badgeCount]="badgeCount"
          >
          </app-gis-primary-button>
        </ng-container>
        <ng-container>
          <app-gis-right-side-panel
            [isOpen]="isFiltersPanelOpen"
            [title]="'Set Csv data Filter'"
            (close)="closeFiltersPanel()"
          >
            <app-manage-csv-data-filter
              [columnNameList]="selectedFilterColumn"
              (resetFilter)="resetFilter()"
              (sendFilteredSectionData)="filterApply($event)"
            ></app-manage-csv-data-filter>
          </app-gis-right-side-panel>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="displayColumn.length > 0">
      <!-- Data Table -->
      <app-gis-data-table
        (pageChange)="onPageChange($event)"
        [data]="dataSource"
        [columns]="displayColumn"
        [buttons]="buttonConfig"
        [totalCount]="totalCount"
        (selectedRowsChange)="onSelectedRowsChange($event)"
      ></app-gis-data-table>
    </ng-container>
  </div>
</form>

<!--

  <div class="">
    <button mat-button [matMenuTriggerFor]="Filter">Filter</button>
    <mat-menu #Filter="matMenu" panelClass="custom-menu-panel">
      <div class="filter-form-container" (click)="$event.stopPropagation()">
        <div class="form-group">
                <div class="filter-selection">
            <app-gis-select
              [label]="'Select filter'"
              [options]="columnFilteroptions"
            ></app-gis-select>
          </div>

          <div class="filter-selection">
            <app-gis-custom-input
              label="Enter filter value"
              [placeholder]="'Enter your table name'"
              (click)="$event.stopPropagation()"
            ></app-gis-custom-input>
          </div>


          <div class="add-filter-btn">

            <button mat-icon-button class="custom-button">
              <mat-icon>close</mat-icon>
            </button>

            <button
              mat-icon-button
              class="custom-button"
              (click)="addFilter()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <div class="form-actions">
          <button class="gis-reset-button" (click)="resetFilters()">
            Reset all
          </button>
          <button class="gis-apply-button" (click)="applyFilters()">
            Apply
          </button>
        </div>
      </div>
    </mat-menu>
  </div>
-->
