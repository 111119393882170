<div>
  <button
    [class]="class"
    [type]="button_type"
    [disabled]="isDisabled"
    (click)="onActions()"
  >
  <ng-container *ngIf="button_label == 'Filter'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 200" class="filter-icon">
      <rect x="73" y="20" width="244" height="10" fill="#000" />
      <circle cx="126" cy="25" r="20" fill="#000" />
      <rect x="73" y="80" width="244" height="10" fill="#000" />
      <circle cx="195" cy="85" r="20" fill="#000" />
      <rect x="73" y="140" width="244" height="10" fill="#000" />
      <circle cx="253" cy="145" r="20" fill="#000" />
    </svg>

  </ng-container>
  <ng-container *ngIf="button_label == 'Delete' || button_label == 'Delete Selected'">
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 16 16" width="24px" height="24px"><path d="M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.328125 3.671875 14 4.5 14 L 10.5 14 C 11.328125 14 12 13.328125 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 5 5 L 6 5 L 6 12 L 5 12 Z M 7 5 L 8 5 L 8 12 L 7 12 Z M 9 5 L 10 5 L 10 12 L 9 12 Z"/></svg>
  </ng-container>
    {{ button_label }}
    <span *ngIf="isBadgeAdded && badgeCount > 0" class="badge"> {{badgeCount}} </span>
  </button>
</div>
