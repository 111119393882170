<div class="p-2">
    <!-- Data table component -->
    <div>
      <h5 class="heading mt-2">In Process</h5>
     </div>
    <app-gis-data-table
      (pageChange)="onPageChange($event)"
      [data]="selectedData"
      [columns]="columnNameList"
      [buttons]="buttonConfig"
      [totalCount]="totalCount"
      [getStatusClass]="getStatusClass"
    >
    </app-gis-data-table>
    <ngx-loading
    [show]="isLoading"
    [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
  ></ngx-loading>

</div>
