<div class="table-wrapper">
  <mat-toolbar class="matpopup-header">
    <div>
      Attributes Copy Progress
      <button (click)="downloadDataAsText()" *ngIf="selectedData.length">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:svgjs="http://svgjs.com/svgjs"
          width="19"
          height="19"
          x="0"
          y="0"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
          class=""
        >
          <g>
            <g id="Solid">
              <path
                d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"
                fill="#3fc93c"
                data-original="#000000"
                class=""
              ></path>
              <path
                d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"
                fill="#3fc93c"
                data-original="#000000"
                class=""
              ></path>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <button type="button" class="close-btn" (click)="close()">
      <img src="../../../../../assets/icon/Cross.svg" alt="" />
    </button>
  </mat-toolbar>
<div class="p-3">
  <div class="d-flex gap-20 p-0">
    <p class="m-0">Success Count: <b>({{success_Count}})</b></p>
    <p  class="m-0">Failure Count: <b>({{failure_count}})</b></p>
  </div>

    <app-gis-data-table
      [data]="selectedData"
      [columns]="selectedColumns"
      [getStatusClass]="getStatusClass"
    >
    </app-gis-data-table>
    <h3 *ngIf="!selectedData.length">
      No data found
    </h3>
</div>

</div>
