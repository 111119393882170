<div class="position-relative">
  <div class="min-max" [ngClass]="isProgress">
    <span>{{ min }}</span>
    <!-- {{ percent }}% -->
    <span>{{ max }}</span>
  </div>

  <div
    class="w3-round-xlarge"
    [ngClass]="{
      'w3-light-red': percent >= 0 && percent <= 25,
      'w3-light-orange': percent > 25 && percent <= 70,
      'w3-light-green': percent > 70
    }"
  >
    <div
      class="w3-container wprogress-h w3-round-xlarge"
      [ngClass]="{
        'w3-red': percent >= 0 && percent <= 25,
        'w3-orange': percent > 25 && percent <= 70,
        'w3-green': percent > 70
      }"
      [style.width]="percent + '%'"
    >
      {{ percent }}%
    </div>
  </div>
</div>
