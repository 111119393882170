import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";

@Component({
  selector: "app-gis-control-spark-server",
  templateUrl: "./gis-control-spark-server.component.html",
  styleUrls: ["./gis-control-spark-server.component.scss"],
})
export class GisControlSparkServerComponent {
  serverStatusText: string = "Loading Server Status...";
  layerDataGroup: FormGroup;
  isLoading: boolean = true; // Disable toggle during loading
  isServerRunning: boolean = false; // Track server running state
  operationInterval: any; // Interval for operation-specific polling
  @Output() emitStatusOfServer = new EventEmitter();
  constructor(private api: ApiserviceService, private fb: FormBuilder, private notify : NotificationService) {}

  ngOnInit() {
    this.init();
    this.checkServerStatus(); // Call once during initialization

    this.layerDataGroup
      .get("serverStatus")
      ?.valueChanges.subscribe((status) => {
        if (this.isLoading) return; // Ignore changes during loading
        if (status && !this.isServerRunning) {
          this.startServer();
        } else if (!status && this.isServerRunning) {
          this.stopServer();
        }
      });
  }

  init() {
    this.layerDataGroup = this.fb.group({
      serverStatus: [{ value: null, disabled: true }], // Default disabled state
    });
  }

  toggleServerStatus(): void {
    const status = this.layerDataGroup.get("serverStatus")?.value;
    if (status && !this.isServerRunning) {
      this.startServer();
    } else if (!status && this.isServerRunning) {
      this.stopServer();
    }
  }

  startServer(): void {
    if (this.isServerRunning) return; // Prevent unnecessary calls
    const apiUrl = `start-server`;
    this.isLoading = true;
    this.layerDataGroup.get("serverStatus")?.disable(); // Disable toggle
    this.api.getGesData(apiUrl).subscribe({
      next: () => {
        this.serverStatusText = "Starting Server...";
        console.log("Server starting...");
        this.pollServerStatus("VM running");
      },
      error: (err) => {
        console.error("Error starting server:", err);
        this.layerDataGroup
          .get("serverStatus")
          ?.setValue(false, { emitEvent: false }); // Reset toggle
        this.isLoading = false;
        this.layerDataGroup.get("serverStatus")?.enable();
      },
    });
  }

  stopServer(): void {
    if (!this.isServerRunning) return; // Prevent unnecessary calls
    const apiUrl = `stop-server`;
    this.isLoading = true;
    this.layerDataGroup.get("serverStatus")?.disable(); // Disable toggle
    this.api.getGesData(apiUrl).subscribe({
      next: (res: any) => {
        console.log(res, "Stop server response...");
        if (res?.message === "Process is still running. Please try again later.") {
          // Retain the toggle state as 'checked' since the server is still running
          this.serverStatusText = "Server is Started";
          this.notify.notify("Process is still running. Please try again later.", "warn", 7000)
          this.layerDataGroup
            .get("serverStatus")
            ?.setValue(true, { emitEvent: false });
          this.isLoading = false;
          this.layerDataGroup.get("serverStatus")?.enable();
        } else {
          // Proceed with the normal stop process if no such message
          this.serverStatusText = "Stopping Server...";
          console.log("Server stopping...");
          this.pollServerStatus("VM deallocated");
        }
      },
      error: (err) => {
        console.error("Error stopping server:", err);
        this.layerDataGroup
          .get("serverStatus")
          ?.setValue(true, { emitEvent: false }); // Reset toggle
        this.isLoading = false;
        this.layerDataGroup.get("serverStatus")?.enable();
      },
    });
  }


  pollServerStatus(targetStatus: string): void {
    const apiUrl = "get-status";
    clearInterval(this.operationInterval); // Clear any previous intervals
    let attempts = 0;
    const maxAttempts = 10; // Limit retries to prevent infinite polling

    this.operationInterval = setInterval(() => {
      if (attempts >= maxAttempts) {
        clearInterval(this.operationInterval);
        this.serverStatusText = `Unable to confirm ${
          targetStatus === "VM running" ? "start" : "stop"
        } status. Please try again.`;
        this.isLoading = false;
        this.layerDataGroup.get("serverStatus")?.enable();
        return;
      }

      this.api.getGesData(apiUrl).subscribe({
        next: (res: any) => {
          console.log(res, "Polling server status...");
          const allServersInTargetState = res.success.every(
            (server: any) => server.status.displayStatus === targetStatus
          );

          if (allServersInTargetState) {
            clearInterval(this.operationInterval); // Stop polling
            this.isServerRunning = targetStatus === "VM running";
            this.serverStatusText = this.isServerRunning
              ? "Server is Started"
              : "Server is Stopped";
            this.layerDataGroup
              .get("serverStatus")
              ?.setValue(this.isServerRunning, { emitEvent: false });
            this.isLoading = false;
            this.layerDataGroup.get("serverStatus")?.enable();
            this.disabledCalculationWhenServerStopped();
          } else {
            attempts++;
          }
        },
        error: (err) => {
          console.error("Error during polling:", err);
        },
      });
    }, 3000); // Poll every 3 seconds
  }

  checkServerStatus(): void {
    const apiUrl = "get-status";
    this.isLoading = true;
    this.api.getGesData(apiUrl).subscribe({
      next: (res: any) => {
        console.log(res, "Server status fetched successfully.");
        const allServersRunning = res.success.every(
          (server: any) => server.status.displayStatus === "VM running"
        );

        this.isServerRunning = allServersRunning;
        this.serverStatusText = allServersRunning
          ? "Server is Started"
          : "Please Start Server";
        this.layerDataGroup
          .get("serverStatus")
          ?.setValue(allServersRunning, { emitEvent: false });
        this.layerDataGroup.get("serverStatus")?.enable(); // Enable toggle
        this.isLoading = false;
        this.disabledCalculationWhenServerStopped()
      },
      error: (err) => {
        console.error("Error fetching server status:", err);
        this.serverStatusText = "Please Start Server";
        this.layerDataGroup.get("serverStatus")?.setValue(false, {
          emitEvent: false,
        });
        this.layerDataGroup.get("serverStatus")?.enable();
        this.isLoading = false;
      },
    });
  }
  disabledCalculationWhenServerStopped(){
   this.emitStatusOfServer.emit(this.isServerRunning);
   console.log(this.isServerRunning, "this.isServerRunning");

  }
}
