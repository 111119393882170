import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";

@Component({
  selector: "app-manage-csv-data-filter",
  templateUrl: "./manage-csv-data-filter.component.html",
  styleUrls: ["./manage-csv-data-filter.component.scss"],
})
export class ManageCsvDataFilterComponent implements OnInit {
  @Input() columnNameList = [];
  @Output() sendFilteredSectionData = new EventEmitter<any[]>();
  @Output() resetFilter = new EventEmitter<any[]>();
  dynamicForm: FormGroup;
  selectedFilterOptions: [] = [];
  options: any;
  constructor(
    private fb: FormBuilder,
    private notify: NotificationService,
    private cdr: ChangeDetectorRef
  ) {
    this.dynamicForm = this.fb.group({
      rows: this.fb.array([this.createRow()]),
    });
  }

  get rows(): FormArray {
    return this.dynamicForm.get("rows") as FormArray;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["columnNameList"]) {
      // Ensure columnNameList is populated before trying to map it
      if (this.columnNameList && this.columnNameList.length > 2) {
        // Exclude the first and last elements
        const filteredList = this.columnNameList.slice(1, -1);

        // Map the filtered array into DropdownOption array
        this.options = filteredList.map((column: string) => ({
          value: column, // value is a string
          viewValue: column, // label is a string
        }));
      }
    }
    if (changes["isFiltersPanelOpen"]) {
      console.log(
        "isFiltersPanelOpen changed:",
        changes["isFiltersPanelOpen"].currentValue
      );
    }
  }
  ngOnInit() {}

  createRow(): FormGroup {
    return this.fb.group({
      columnName: [null, Validators.required], // Dropdown value
      columnValue: ["", Validators.required], // Input value
    });
  }

  addRow(): void {
    if (this.dynamicForm.invalid) {
      this.notify.notify(
        "Please fill out all required fields before adding a new item",
        "warn"
      );
      return;
    }
    this.rows.push(this.createRow());
    this.cdr.detectChanges();
  }

  removeRow(index: number): void {
    this.rows.removeAt(index);
  }
  onSubmit(): void {
    if (this.dynamicForm.valid) {
      const selectedFilterOptions = this.dynamicForm.value.rows;

      // Emit the rows data
      this.sendFilteredSectionData.emit(selectedFilterOptions);

      // Log the rows for debugging
      console.log(selectedFilterOptions, "this.dynamicForm.value.rows");
    } else {
      console.error("Form is invalid");
    }
  }

  getFilteredOptions(index: number) {
    // Ensure options is defined before filtering
    if (!this.options || !Array.isArray(this.options)) {
      return [];
    }
    // Get all selected values
    const selectedValues = this.rows.controls
      .map((control) => control.get("columnName")?.value)
      .filter((value) => value); // Filter out empty values

    // Return options excluding already selected values, except the current field
    return this.options.filter(
      (option) =>
        !selectedValues.includes(option.value) ||
        selectedValues[index] === option.value
    );
  }

  resetForm(): void {
    const rows = this.dynamicForm.get("rows") as FormArray;

    // Clear all rows except the first one
    while (rows.length > 1) {
      rows.removeAt(rows.length - 1);
    }

    // Reset the first row to its default state
    rows.at(0).reset({
      columnName: null,
      columnValue: "",
    });

    // Optionally reset the form validity state
    this.dynamicForm.markAsPristine();
    this.dynamicForm.markAsUntouched();
    this.resetFilter.emit();
  }
}
