import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-gis-right-side-panel',
  templateUrl: './gis-right-side-panel.component.html',
  styleUrls: ['./gis-right-side-panel.component.scss']
})
export class GisRightSidePanelComponent {
  @Input() isOpen: boolean = false; // Control the panel's visibility
  @Input() title: string = '';      // Title of the panel
  @Output() close = new EventEmitter<void>(); // Event for panel close
  @Input() cancelButtonLabel: string = 'Cancel'; // Label for cancel button
  @Input() submitButtonLabel: string = 'Submit'; // Label for submit button
  @Output() cancel = new EventEmitter<void>();   // Event for cancel button
  @Output() submit = new EventEmitter<void>();   // Event for submit button

  onClose() {
    this.close.emit();
  }

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    this.submit.emit();
  }
}
