<form [formGroup]="csvTempFormGroup">
  <div class="">
    <div class="d-flex align-items-center pt-4 justify-content-between pl-0">
      <!-- Back Button (div) with SVG Icon and Translated Text -->
      <div class="d-flex align-items-center justify-content-between pl-0">
        <button
          (click)="goBack()"
          type="button"
          class="border-none bg-none cursor-pointer"
        >
          <svg
            class="mx-1"
            xmlns="http://www.w3.org/2000/svg"
            width="13.991"
            height="24.817"
            viewBox="0 0 12.991 23.817"
          >
            <path
              id="XMLID_225_"
              d="M23.5,75.317a1.083,1.083,0,0,0-1.531,0l-10.06,10.06L1.849,75.317A1.083,1.083,0,0,0,.318,76.848L11.144,87.674a1.082,1.082,0,0,0,1.531,0L23.5,76.848A1.082,1.082,0,0,0,23.5,75.317Z"
              transform="translate(87.991 0) rotate(90)"
              fill="#ff9d5a"
              stroke="#ff9d5a"
              stroke-width="2"
            />
          </svg>
        </button>
        <div class="d-flex align-items-center justify-content-between gap-20">
          <h6 class="pl-2 m-0 font-weight-bold"> {{title}} </h6>
          <div class="d-flex align-items-center gap-20">
            <div>
              <label class="m-0"><b>File Type</b></label>
              <input
                type="text"
                class="f-14 form-control input-background"
                formControlName="file_type"
              />
            </div>
            <div class="">
              <label class="m-0"><b>Table Name</b></label>
              <input
                type="text"
                class="f-14 form-control input-background"
                formControlName="table_name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex justify-content-between">
          <button  class="gis-primary-button" (click)="submit()" type="button">{{button}}</button>
          <button class="cancel-btn gis-secondary-button" (click)="goBack()">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <!-- <app-gis-form-table
   [dataTypeList]="dataTypes">
  </app-gis-form-table> -->
  </div>
  <div class="csv-wrapper table-wrapper">
    <div class="form-wrapper">
      <div formArrayName="csvTempArray">
        <div class="scroll">
          <table cdkDropList (cdkDropListDropped)="onTableDrop($event)" [cdkDropListDisabled]="dragDisabled">
            <thead>
              <tr>
                <th class="serial-number">S.NO</th>
                <th class="layer-name">Name</th>
                <th class="label">Column Name</th>
                <th class="store-name">Data Type</th>
                <th class="min-zoom">Json Key</th>
                <th class="max-zoom">Ref. Table</th>
                <th class="type">Ref. Col. Name</th>
                <th class="type">Ref. Condition Name</th>
                <th class="status">Required</th>
                <th class="action">Action</th>
              </tr>
            </thead>
            <tbody #scroll>
              <tr cdkDrag
                *ngFor="
                  let csvTempItem of csvTempFormArray.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <td class="serial-number"> <span class="drag-icon" cdkDragHandle  (mousedown)="dragDisabled = false;"><img src="assets/images/drag-icon.svg" alt=""></span>{{ i + 1 }}</td>
                <td class="layer-name">
                  <div class="error-message-wrapper">
                    <input
                      type="text"
                      matInput
                      class="f-14 form-control input-background"
                      #layarName
                      formControlName="name"
                      required
                      maxlength="21"
                    />
                  </div>
                </td>
                <td class="label">
                  <div class="error-message-wrapper">
                    <input
                      type="text"
                      matInput
                      class="f-14 form-control input-background"
                      formControlName="col_name"
                      maxlength="21"
                      required
                    />

                    <div></div>
                  </div>
                </td>

                <td class="data-type">
                  <div class="error-message-wrapper">
                    <!-- <input
                      type="text"
                      matInput

                      formControlName="data_type"
                      required
                      maxlength="21"
                    /> -->
                    <mat-form-field appearance="fill">
                      <mat-select  formControlName="data_type" >
                        <mat-option  *ngFor="let data_type of dataTypes" [value]="data_type.name">{{data_type.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>
                <td class="json-key">
                  <div class="error-message-wrapper">
                    <input
                      matInput
                      type="text"
                      class="f-14 form-control input-background"
                      formControlName="json_key"
                      required
                      #minZoom
                    />
                  </div>
                </td>
                <td class="error-message-wrapper">
                  <div class="">
                    <input
                      matInput
                      type="text"
                      class="f-14 form-control input-background"
                      formControlName="refTable"
                      required
                    />
                  </div>
                </td>
                <td class="error-message-wrapper">
                  <div class="">
                    <input
                      type="text"
                      matInput
                      class="f-14 form-control input-background"
                      formControlName="refColName"
                    />
                  </div>
                </td>
                <td>
                  <div class="">
                    <input
                      type="text"
                      matInput
                      class="f-14 form-control input-background"
                      formControlName="refConditionName"
                    />
                  </div>
                </td>
                <td class="status">
                  <div class="">
                    <mat-slide-toggle
                      formControlName="required"
                      [checked]="csvTempItem.get('required').value"
                    ></mat-slide-toggle>
                  </div>
                </td>
                <td class="action action-coulmn">
                  <div class="d-flex">
                    <button
                      type="button"
                      class="action-button"
                      [ngClass]="{
                        'visibility-hidden': csvTempFormArray.length <= 1
                      }"
                      mat-icon-button
                      (click)="removeRow(i, csvTempItem)"
                      matTooltip="Remove"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                    <button
                      *ngIf="
                        i === csvTempFormArray.length - 1 &&
                        csvTempFormArray.length != 10
                      "
                      class="action-button"
                      type="button"
                      mat-icon-button
                      (click)="addRow()"
                      matTooltip="Add"
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>
