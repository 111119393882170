<form [formGroup]="dynamicForm">
  <div formArrayName="rows">
    <div
      *ngFor="let row of rows.controls; let i = index"
      [formGroupName]="i"
      class="form-row"
      [ngClass]="{
        'is-last-row-activated':
          i === rows.length - 1 &&
          columnNameList.length != rows.length &&
          rows.length > 1
      }"
    >
      <!-- Dropdown for Column Name -->
      <!-- <select >
        <option value="" disabled>Select Column</option>
        <option *ngFor="let option of columnOptions" [value]="option">{{ option }}</option>
      </select> -->
      <div class="custom-control">
        <app-gis-select
          formControlName="columnName"
          [label]="'Column Name'"
          [options]="getFilteredOptions(i)"
        >
        </app-gis-select>
      </div>
      <div class="custom-control">
        <app-gis-custom-input
          [label]="'Enter Value'"
          formControlName="columnValue"
        ></app-gis-custom-input>
      </div>
      <!-- Remove Button -->
      <button
        type="button"
        (click)="removeRow(i)"
        *ngIf="rows.length > 1"
        mat-icon-button
        class="custom-button add-row-btn"
      >
        <mat-icon>close</mat-icon>
      </button>
      <!-- Add Button -->
      <!-- <button type="button"  >+</button> -->
      <button
        mat-icon-button
        class="custom-button remove-row-btn"
        *ngIf="i === rows.length - 1 && columnNameList.length != rows.length"
        (click)="addRow(); $event.stopPropagation()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <!-- reset Button -->
  <div class="side-panel-footer gap-24 d-flex">
    <!-- Cancel Button -->
    <app-gis-primary-button
      [button_label]="'Reset All'"
      [class]="'reset-btn'"
      [isDisabled]="false"
      (onAction)="resetForm(); $event.stopPropagation()"
    >
    </app-gis-primary-button>
    <!-- apply filter Button -->
    <app-gis-primary-button
      [button_label]="'Apply Filter'"
      [class]="'apply-btn'"
      [isDisabled]="dynamicForm.invalid"
      (onAction)="onSubmit(); $event.stopPropagation()"
    >
    </app-gis-primary-button>
  </div>
</form>
